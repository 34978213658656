
.seismicGradientContainer {
    position: absolute;
    top: 80px;
    left: 10px;
    height: 70%;
    padding: 10px 10px 20px;
    border-radius: 8px;
    z-index: 10000;
    background-color: #FFFFFF;
    box-shadow: #9c9a9a 0px 2px 6px;
}

.container{
    height: 100%
}

.sinnerContainer{
    height: 95%;
    overflow: hidden;
}

.seismic{
    width: 25px;
    height: 99%;
    float: left;
    background: linear-gradient(darkred 0%, red 25%,  white 50%, blue 75%, darkblue 100%);
}

.seismic_scale{
    width: 45px;
    height: 99%;
    overflow: hidden;
    float: left;
}

.seismic_scale div{

    font-family: arial;
    font-size: 10px;
    height: 12.2%;
    text-align: right;
}

.h10{
    padding-bottom: 16px
}
