.cm-button{
    padding: 6px;
    border-radius: 8px;
    border: 1px solid #bbb;
    z-index: 600;
    background-color: #9ed3a0;
    color: #000000;
    text-align: center;
    cursor: pointer;
    float: left;
    margin-left: 30px;
}