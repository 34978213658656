.sidePanel {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-bottom: 6px;
    z-index: 15000;
    box-shadow: #9c9a9a 0px 2px 6px;
    background-color: #423758;
    border-bottom: thin solid #fff;
    border-top: thin solid #685981;
}

.selectorButton {
    float: left;
    padding: 6px;
    margin-left: 2px;
    border-radius: 0px;
    cursor: pointer;
    font-size: 14px;
}

.selectorButton.active{
    border: 1px solid #e1dede;
    background-color: white;
    border-radius: 4px;
}

.selectorButton.inActive{
    border: 1px solid #e1dede;
    border-radius: 4px;
    background-color: #ddd;
    color: grey
}

.divider{
    border-right: 1px dotted #bbb;
    height: 30px;
    float: left;
    margin-right: 10px;
    margin-left: 10px;
}

.data-selector-container {
    text-align: left;
    float: left;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    background: #ccc;
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
}

.menuItem {
    float: left;
}