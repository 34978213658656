#spinnerBG {
    z-index: 10000;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #0a68b4;
    opacity: 0.5;
}

#spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    flex-direction: column;
}