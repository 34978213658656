.back-to-country-button{
    position: absolute;
    top: 70px;
    right: 10px;
    height: 53px;
    padding: 12px 12px 15px;
    z-index: 600;
    width: 140px;
    box-shadow: #9c9a9a 0px 2px 6px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid #bbb;
    background-color: #9ed3a0;
    color: #000000;
    text-align: center;
    cursor: pointer;
}
