.sliderContainer{
    position: absolute;
    padding: 20px;
    top: 70px;
    left: 130px;
    background-color: #ffffff;
    border-radius: 4px;
    border: thin solid #bbb;
    z-index: 13000;
    width: 300px;
}

.changeMapSlider{
    width:90%;
    margin-left: 20px;
    margin-top: 30px;
}

.mark {
    width: 8px;
    height: 8px;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: 15px;
}

.track{
    top: 20px;
    height: 2px;
    background-color: black;
}

.thumb{
    top: 10px;
    margin-left: 0px;
    width: 20px;
    height: 18px;
    border: 1px solid black;
    border-radius: 50%;
    line-height: 18px;
    background-color: #000;
    color: #fff;
    text-align: center;
    cursor: grab;
}

.stepInfo {
    font-size: 12px;
}