.charts {
    position: absolute;
    width: 70vw;
    height: 18vh;
    bottom: 90px;
    margin-left: 120px;
    z-index: 10000;
    background-color: #f2f2f2;
    border-radius: 6px;
    box-shadow: #9c9a9a 0px 2px 6px;
}

.custom-tooltip{
    border: 1px solid #bbb;
    border-radius: 3px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ffffff;
}

.label{
    font-size: 10px;
    font-weight: 800;
}

.desc{
    font-size: 10px;
}

.chartContainer {
    width: 100%;
    height: 100%;
    z-index: 15000;
    position: absolute;
    top: 0px;
    left: 0px;
}

.csv_btn{
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 20px;
    padding: 1px 4px;
    cursor: pointer;
    top: 3px;
    right: 30px;
    text-decoration: none;
    color: #262726;
}

 .chartDragger {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 16000;
}

 #windowTitle {
     width: 100%;
     height: 30px;
     background-color: #453d6e;
     cursor: move;
     color: #fff;
     font-size: 14px;
 }

.chartWrapper {
    position: relative;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: #9c9a9a 0px 2px 6px;
}
.yAxis {
    margin-left: 20px;
    margin-bottom: 0;
}

.lineChart {
display: block;
width: 100%;
height: 100%;
}

.legend {
display: block;
padding: 10px;
overflow: auto;
}
/*
.legend DIV{
font-size: 12px;
margin: 6px;
padding: 2px;
float: right;
}

.legend DIV SPAN{
font-weight: 800;
}
*/

.modelButton{
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.countryHeader{
    text-transform: capitalize;
    float: left;
    padding-left: 13px;
    margin-top: 6px;
}


.changeMapHeader{
    margin-top: 40px;
    margin-left: 100px;
}

.metaDescriptions{
    float: right;
    text-align: left;
    margin-right: 50px;
    font-size: 12px;
}