
.closer{
    border: 1px solid #bbbbbb;
    color: #000000;
    background-color: #ffffff;
    position: absolute;
    right: 2px;
    top: 3px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 1px;
}