.modelDescriptionContainer {
    width: 400px;
    position: absolute;
    top: 140px;
    right: 10px;
    z-index: 16000;
    background-color: #ffffff;
    border-radius: 6px;
    display: block;
    overflow: auto;
    margin-bottom: 20px;
    box-shadow: #9c9a9a 0px 2px 6px;
}

#modelTitle {
    width: 100%;
    height: 30px;
    background-color: #453d6e;
    cursor: move;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #fff;
    font-size: 14px;
}

.heading{
    margin-top: 30px;
    margin-left: 20px;
    display: block;
    font-size: 14px;
    text-transform: uppercase;

}

.description{
    margin: 20px;
    display: block;
    font-size: 14px;
    font-weight: bold;
}
