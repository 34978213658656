.popupContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    width: 20vw;
    z-index: 10100;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: #9c9a9a 0px 2px 6px;
    text-align: center;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.messageContainer{
    padding: 0%;
}

.error-heading{
    display: block;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    background-color: #2D0A41;
    color: white;
    padding: 12px;
}

.description{
    margin: 20px;
    display: block;
    font-size: 14px;
    font-weight: bold;
}
