.selector-description {
    font-size: 12px;
    padding-bottom: 2px;
    padding-left: 4px;
    font-weight: 800;
    text-align: left;
    height: 14px;
    color: #fff;
}

.dropDown-container {
    width: 200px;
}

.basic-multi-select{
    float: left;
    width: 200px;
    padding-right: 20px;
    z-index: 15000;
    max-height: 30px;
}

.select__control, .select__control--is-focused{
    max-height: 30px;
}


/*_______________________________*/
.selector_button{
    color: #b5b4b4;
    border-radius: 4px;
    border: thin solid #bbb;
    font-size: 14px;
    background: #fff;
    padding: 4px;
    margin-right: 16px;
    max-width: 150px;
}

.selectorTextContainer {
    max-width: 90px;
    width: 90px;
    max-height: 16px;
    overflow: hidden;
    float: left;
    margin-right: 4px;
    margin-top: 4px;
    text-align: left;
    font-size: 12px;
}

.selectorIcons {
    float: right;

}