
.viewsLogo{
    position: absolute;
    bottom: 10px;
    right: 0px;
    padding: 10px 20px 10px 10px;
    border-radius: 8px;
    z-index: 10000;
    max-height: 54px;
    text-align: right;
}
