.opacityContainer2{
    position: absolute;
    bottom: 30px;
    left: 10px;
    height: 70px;
    padding: 20px;
    border-radius: 8px;
    z-index: 600;
    background-color: #FFFFFF;
    width: 210px;
    box-shadow: #9c9a9a 0px 2px 6px;
    text-align: center;
}

.opacityContainer {
    position: absolute;
    bottom: 10px;
    left: 50px;
    height: 30px;
    padding: 12px;
    padding-top: 8px;
    padding-bottom: 15px;
    border-radius: 3px;
    z-index: 600;
    background-color: #FFFFFF;
    width: 255px;
    box-shadow: #9c9a9a 0px 2px 6px;
    text-align: center;
    font-size: 12px;
}

.opacitySlider{
    width: 200px;
    padding: 0px;
    top: -8px;
    float: left;
}

.minMaxContainer {
    float: left;
    height: 30px;
    padding: 2px;
}

.sliderWrapper {
    float: left;
    height: 30px;
}

.mark {
    width: 0px;
    height: 0px;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: 10px;
}

.track{
    height: 2px;
    background-color: black;
}

.thumb{
    top: 10px;
    margin-left: 0px;
    width: 11px;
    height: 10px;
    padding: 2px;
    font-size: 10px;
    border: 1px solid black;
    border-radius: 5px;
    line-height: 18px;
    background-color: #004c99;
    color: #fff;
    text-align: center;
    cursor: grab;
}